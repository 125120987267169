import React from 'react';
import Image from 'next/image';

import StoreImageOne from '@assets/images/home-image-left.jpg';
import StoreImageTwo from '@assets/images/okay-humans-home-right.jpg';
import Frames from '@assets/images/svg/frame.svg';
import StoreIllustration from '@assets/images/svg/store.svg';
import { HomeComponentProps } from '@models/home';

import {
  BookNowButton,
  CenterBlock,
  CenterBlockText,
  CenterText,
  StoreImageWrapper,
} from './home.styled';

const BookLocation: React.FunctionComponent<HomeComponentProps> = ({
  handleBookNow,
}) => {
  const handleBooking = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleBookNow();
  };

  return (
    <StoreImageWrapper>
      <Image
        src={StoreImageOne}
        alt="This is our guest lounge, which includes complimentary tea and almonds."
        width={590}
        height={564}
        objectFit="cover"
      />
      <CenterBlock>
        <Frames
          css={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
        />
        <CenterBlockText>
          <StoreIllustration />
          <CenterText>
            Brentwood
            <br />
            Now Open!
          </CenterText>
          <BookNowButton type="button" onClick={handleBooking}>
            Book Now
          </BookNowButton>
        </CenterBlockText>
      </CenterBlock>
      <Image
        src={StoreImageTwo}
        alt="This is a guest suite, where a therapy session takes place."
        width={590}
        height={564}
        objectFit="cover"
      />
    </StoreImageWrapper>
  );
};

export default BookLocation;
